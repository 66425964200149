<template>
  <div class="chart-train-container">
    <div class="chart-content">
      <Row>
        <Col span="8">&nbsp;</Col>
        <Col span="8">
          <Date-picker @on-change="changeTimeOne" type="daterange" placeholder="选择日期"></Date-picker>
        </Col>
        <Col span="8">&nbsp;</Col>
      </Row>
      <div class="chart" ref="barData"></div>
    </div>
    <div class="chart-content">
      <Form :label-width="80" label-position="right"> 
        <Row>
          <Col span="6">
            <Date-picker @on-change="changeTimeTwo" v-model="timeTwo" :clearable="false" type="daterange" placeholder="选择日期"></Date-picker>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="6">
            <Form-item label="项目：">
              <Select v-model="searchTwo.type" placeholder="请选择">
                <Option :value="v.name" v-for="v in typeList" :key="v.name">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
        </Row>
        <Row>
          <Col span="6">
            <Form-item label="省份：">
              <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="pro_name"></Input>
              <Select clearable v-model="searchTwo.pid" placeholder="请选择" @on-change="changeProTwo" v-else>
                <Option :value="v.id" v-for="v in proListTwo" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="6">
            <Form-item label="城市：">
              <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="city_name"></Input>
              <Select clearable v-model="searchTwo.cid" placeholder="请选择" @on-change="changeCityTwo" v-else>
                <Option :value="v.id" v-for="v in cityListTwo" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="6">
            <Form-item label="门店：">
              <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="branch_name"></Input>
              <Select clearable v-model="searchTwo.sid" placeholder="请选择" v-else>
                <Option :value="v.id" v-for="v in storeListTwo" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="2">
            <Button type="primary" class="search-btn" @click="handleTwo()">搜索</Button>
          </Col>
        </Row>
      </Form>
      <div class="card-wrapper">
        <Row :gutter="40">
          <Col span="8">
            <div class="card-module-nofoot bg1">
              <Row>
                <Col span="12">
                  <Row type="flex" justify="center" align="middle">
                    <Col span="8"><img src="@/assets/img/icon-1.png" alt="" width="40px" height="40px"></Col>
                    <Col span="16">
                      <p v-show="typeTwo === '总消耗能量'" :title="'总消耗能量最低值' + min + 'kcal'"><em class="font_30">{{min}}</em>kcal</p>
                      <p v-show="typeTwo === '总训练次数'" :title="'总训练次数最低值' + min + '次'"><em class="font_30">{{min}}</em>次</p>
                      <p v-show="typeTwo === '总训练时长'" :title="'总训练时长最低值' + min + 'min'"><em class="font_30">{{min}}</em>min</p>
                      <h2 :title="typeTwo + '最低值'">{{typeTwo}}最低值</h2>
                    </Col>
                  </Row>
                </Col>
                <Col span="12">
                  <Row type="flex" justify="center" align="middle">
                    <Col span="8"><img src="@/assets/img/icon-2.png" alt="" width="40px" height="40px"></Col>
                    <Col span="16">
                      <p v-show="typeTwo === '总消耗能量'" :title="'总消耗能量最高值' + max + 'kcal'"><em class="font_30">{{max}}</em>kcal</p>
                      <p v-show="typeTwo === '总训练次数'" :title="'总训练次数最高值' + max + '次'"><em class="font_30">{{max}}</em>次</p>
                      <p v-show="typeTwo === '总训练时长'" :title="'总训练时长最高值' + max + 'min'"><em class="font_30">{{max}}</em>min</p>
                      <h2 :title="typeTwo + '最高值'">{{typeTwo}}最高值</h2>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span="8">
            <div class="card-module-extra bg2">
              <h2 v-show="typeTwo === '总消耗能量'" :title="'总消耗能量平均值' + average + 'kcal'">{{typeTwo}}平均值 <em class="font_30">{{average}}</em>kcal</h2>
              <h2 v-show="typeTwo === '总训练次数'" :title="'总训练次数平均值' + average + '次'">{{typeTwo}}平均值 <em class="font_30">{{average}}</em>次</h2>
              <h2 v-show="typeTwo === '总训练时长'" :title="'总训练时长平均值' + average + 'min'">{{typeTwo}}平均值 <em class="font_30">{{average}}</em>min</h2>
            </div>
          </Col>
          <Col span="8">
            <div class="card-module-extra bg3">
              <h2 v-show="typeTwo === '总消耗能量'" :title="'总消耗能量中位数' + median + 'kcal'">{{typeTwo}}中位数 <em class="font_30">{{median}}</em>kcal</h2>
              <h2 v-show="typeTwo === '总训练次数'" :title="'总训练次数中位数' + median + '次'">{{typeTwo}}中位数 <em class="font_30">{{median}}</em>次</h2>
              <h2 v-show="typeTwo === '总训练时长'" :title="'总训练时长中位数' + median + 'min'">{{typeTwo}}中位数 <em class="font_30">{{median}}</em>min</h2>
            </div>
          </Col>
        </Row>
      </div>
    </div>
    <div class="chart-content">
      <Form :label-width="80" label-position="right"> 
        <Row>
          <Col span="6">
            <Date-picker @on-change="changeTimeThree" v-model="timeThree" :clearable="false" type="daterange" placeholder="选择日期"></Date-picker>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="6">
            <Form-item label="项目：">
              <Select v-model="searchThree.type" placeholder="请选择">
                <Option :value="v.name" v-for="v in typeList" :key="v.name">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
        </Row>
        <Row>
          <Col span="6">
            <Form-item label="省份：">
              <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="pro_name"></Input>
              <Select clearable v-model="searchThree.pid" placeholder="请选择" @on-change="changeProThree" v-else>
                <Option :value="v.id" v-for="v in proListThree" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="6">
            <Form-item label="城市：">
              <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="city_name"></Input>
              <Select clearable v-model="searchThree.cid" placeholder="请选择" @on-change="changeCityThree" v-else>
                <Option :value="v.id" v-for="v in cityListThree" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="6">
            <Form-item label="门店：">
              <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="branch_name"></Input>
              <Select clearable v-model="searchThree.sid" placeholder="请选择" v-else>
                <Option :value="v.id" v-for="v in storeListThree" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="2">
            <Button type="primary" class="search-btn" @click="handleThree()">搜索</Button>
          </Col>
        </Row>
      </Form>
      <table class="mytable">
        <template v-if="!branchName">
          <tr>
            <td class="tdl">所有店面没上传人数百分比</td>
            <td class="tdr"><span class="green-word">{{(no_up * 100).toFixed()}}%</span></td>
          </tr>
          <template v-if="typeThree === '总消耗能量'">
            <tr v-for="show in showList" :key="show.name">
              <td class="tdl">
                {{show.name}}<span class="project-name">{{typeThree}}</span>上传数据总和
              </td>
              <td class="tdr">{{show.count}}kcal</td>
            </tr>
            <tr>
              <td class="tdl green-bg">{{typeThree}}</td>
              <td class="tdr gray-bg"><span class="green-word">{{total}}</span>kcal</td>
            </tr>
          </template>
          <template v-if="typeThree === '总训练次数'">
            <tr v-for="show in showList" :key="show.name">
              <td class="tdl">
                {{show.name}}<span class="project-name">{{typeThree}}</span>上传数据总和
              </td>
              <td class="tdr">{{show.count}}次</td>
            </tr>
            <tr>
              <td class="tdl green-bg">{{typeThree}}</td>
              <td class="tdr gray-bg"><span class="green-word">{{total}}</span>次</td>
            </tr>
          </template>
          <template v-if="typeThree === '总训练时长'">
            <tr v-for="show in showList" :key="show.name">
              <td class="tdl">
                {{show.name}}<span class="project-name">{{typeThree}}</span>上传数据总和
              </td>
              <td class="tdr">{{show.count}}min</td>
            </tr>
            <tr>
              <td class="tdl green-bg">{{typeThree}}</td>
              <td class="tdr gray-bg"><span class="green-word">{{total}}</span>min</td>
            </tr>
          </template>
        </template>
        <template v-if="branchName">
          <tr>
            <td class="tdl">{{branchName}}没上传人数百分比</td>
            <td class="tdr"><span class="green-word">{{(no_up * 100).toFixed()}}%</span></td>
          </tr>
          <tr>
            <td class="tdl">
              {{branchName}}<span class="project-name">总训练次数</span>上传数据总和
            </td>
            <td class="tdr">{{count}}次</td>
          </tr>
          <tr>
            <td class="tdl">
              {{branchName}}<span class="project-name">总消耗能量</span>上传数据总和
            </td>
            <td class="tdr">{{total_use}}kcal</td>
          </tr>
          <tr>
            <td class="tdl">
              {{branchName}}<span class="project-name">总训练时长</span>上传数据总和
            </td>
            <td class="tdr">{{time}}min</td>
          </tr>
        </template>
      </table>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import echarts from 'echarts';
import problemService from '@/services/problemService';
import chartService from '@/services/chartService';
export default {
	data() {
		return {
			searchOne: {
				start_time: '',
				end_time: '',
			},
			searchTwo: {
				start_time: '',
				end_time: '',
				type: '总训练次数',
				pid: '',
				cid: '',
				sid: '',
			},
			searchThree: {
				start_time: '',
				end_time: '',
				type: '总训练次数',
				pid: '',
				cid: '',
				sid: '',
			},
			timeTwo: [Date.now() - 86400000, Date.now()],
			timeThree: [Date.now() - 86400000, Date.now()],
			barChart: '',
			xArr: [],
			yArr: [],
			typeList: [
				{
					name: '总消耗能量',
				},
				{
					name: '总训练次数',
				},
				{
					name: '总训练时长',
				},
			],
			proListTwo: [],
			cityListTwo: [],
			cityTwo: {},
			storeListTwo: [],
			storeTwo: {},
			proListThree: [],
			cityListThree: [],
			cityThree: {},
			storeListThree: [],
			storeThree: {},
			min: '',
			max: '',
			average: '',
			median: '',
			typeTwo: '',
			typeThree: '',
			no_up: '',
			total: '',
			showList: [],
			branchName: '',
			count: '',
			time: '',
			total_use: '',
			power: '',
			pro_name: '',
			city_name: '',
			branch_name: '',
		};
	},
	created() {
		this.power = localStorage.getItem('userPower');
		this.pro_name = JSON.parse(localStorage.getItem('userInfo')).user_info.pro_name;
		this.city_name = JSON.parse(localStorage.getItem('userInfo')).user_info.city_name;
		this.branch_name = JSON.parse(localStorage.getItem('userInfo')).user_info.branch_name;
		if (
			this.power.indexOf('数据统计--查询全国数据的权限') === -1 &&
			this.power.indexOf('数据统计--查询区域数据的权限') === -1
		) {
			this.searchTwo.pid = JSON.parse(localStorage.getItem('userInfo')).user_info.pid;
			this.searchTwo.cid = JSON.parse(localStorage.getItem('userInfo')).user_info.cid;
			this.searchTwo.sid = JSON.parse(localStorage.getItem('userInfo')).user_info.sid;
			this.searchThree.pid = JSON.parse(localStorage.getItem('userInfo')).user_info.pid;
			this.searchThree.cid = JSON.parse(localStorage.getItem('userInfo')).user_info.cid;
			this.searchThree.sid = JSON.parse(localStorage.getItem('userInfo')).user_info.sid;
		}
		problemService.getProblemSelect().then((data) => {
			this.proListTwo = data.branch.pro;
			this.cityTwo = data.branch.city;
			this.storeTwo = data.branch.store;
			this.proListThree = data.branch.pro;
			this.cityThree = data.branch.city;
			this.storeThree = data.branch.store;
		});
		this.searchTwo.start_time = this.filterMMDD(this.timeTwo[0]);
		this.searchTwo.end_time = this.filterMMDD(this.timeTwo[1]);
		this.searchThree.start_time = this.filterMMDD(this.timeThree[0]);
		this.searchThree.end_time = this.filterMMDD(this.timeThree[1]);
		this.getTrainCondition();
		this.getTrainBranch();
	},
	mounted() {
		// echarts实例化
		this.barChart = echarts.init(this.$refs.barData);
		// 初始化实例
		let barOptions = {
			title: {
				text: '各门店累积APP和手动上传数据条数',
				textStyle: {
					color: '#424e67',
					fontWeight: 'normal',
					fontSize: 14,
				},
				left: '45%',
				bottom: 40,
			},
			grid: {
				show: true,
				borderColor: '#e3e3e3',
				bottom: 80,
			},
			color: ['#f1ce7e'],
			xAxis: {
				type: 'category',
				axisLine: { onZero: false },
				data: [],
			},
			yAxis: [
				{
					type: 'value',
				},
			],
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'shadow',
				},
			},
			dataZoom: [
				{
					show: true,
				},
				{
					type: 'inside',
				},
			],
			series: [
				{
					name: '数据条数',
					type: 'bar',
					data: [],
				},
			],
		};
		this.barChart.setOption(barOptions);
		this.barChart.showLoading();
		this.getChartTrain();
	},
	methods: {
		changeProTwo() {
			this.cityListTwo = this.cityTwo[this.searchTwo.pid];
			this.searchTwo.cid = '';
		},
		changeCityTwo() {
			this.storeListTwo = this.storeTwo[this.searchTwo.cid];
			this.searchTwo.sid = '';
		},
		changeProThree() {
			this.cityListThree = this.cityThree[this.searchThree.pid];
			this.searchThree.cid = '';
		},
		changeCityThree() {
			this.storeListThree = this.storeThree[this.searchThree.cid];
			this.searchThree.sid = '';
		},
		getChartTrain() {
			chartService.getChartTrain(this.searchOne).then((data) => {
				this.xArr = [];
				this.yArr = [];
				data.trans.forEach((item) => {
					this.xArr.push(item.name);
					this.yArr.push(item.count);
				});
				this.barChart.hideLoading();
				this.barChart.setOption({
					xAxis: {
						data: this.xArr,
					},
					series: [
						{
							data: this.yArr,
						},
					],
				});
			});
		},
		getTrainCondition() {
			chartService.getTrainCondition(this.searchTwo).then((data) => {
				this.min = data.min;
				this.max = data.max;
				this.average = data.average;
				this.median = data.median;
				this.typeTwo = this.searchTwo.type;
			});
		},
		getTrainBranch() {
			chartService.getTrainBranch(this.searchThree).then((data) => {
				this.no_up = data.no_up;
				this.total = data.total;
				this.showList = data.show;
				this.typeThree = this.searchThree.type;
				if (data.branch) {
					this.branchName = data.branch;
					this.count = data.count;
					this.time = data.time;
					this.total_use = data.total_use;
				} else {
					this.branchName = '';
				}
			});
		},
		changeTimeOne(value) {
			this.searchOne.start_time = value[0];
			this.searchOne.end_time = value[1];
			this.getChartTrain();
		},
		changeTimeTwo(value) {
			this.searchTwo.start_time = value[0];
			this.searchTwo.end_time = value[1];
		},
		changeTimeThree(value) {
			this.searchThree.start_time = value[0];
			this.searchThree.end_time = value[1];
		},
		handleTwo() {
			this.getTrainCondition();
		},
		handleThree() {
			this.getTrainBranch();
		},
		filterMMDD(value) {
			let onedate = new Date(value);
			let strmonth = onedate.getMonth() + 1;
			let strDate = onedate.getDate();
			if (strmonth >= 1 && strmonth <= 9) {
				strmonth = '0' + strmonth;
			}
			if (strDate >= 0 && strDate <= 9) {
				strDate = '0' + strDate;
			}
			return onedate.getFullYear() + '-' + strmonth + '-' + strDate;
		},
	},
};
</script>

<style lang="css" scoped>
</style>
